import pageDataBase from "../data/db_pages"

const RandomPage = () => {

    const randomPage = () =>{
        const pagesAmount = pageDataBase.length
        const randomPage = Math.floor(pagesAmount * Math.random())
        
        
        const redirectTo = `https://vicv.co/${pageDataBase[randomPage]['url']}`
        window.location.replace(redirectTo);
        
    }

 
    // setTimeout(()=>{
        
    // },1000)

    
    
    return randomPage()
}
 
export default RandomPage;