import { useEffect } from "react"
import pageDataBase from "../data/db_pages"

const Notfound = () => {

    const randomPage = () =>{
        const pagesAmount = pageDataBase.length
        const randomPage = Math.floor(pagesAmount * Math.random())
        console.log(randomPage)

    }
   
    
    useEffect(() => {

    
        return () => randomPage();
      }, []);


    return (
        <div className="pageCont" style={{'height':'60vh'}}>
            <h1>notFound</h1>
        </div>
    );
}
 
export default Notfound;