import '../css/quotes.css';
import quoteDB from "../data/db_quotes"

const Quote = () => {

    
    const quoteAmount = quoteDB.length
    const randomPage = Math.floor(quoteAmount * Math.random())
        
    return(
        <div className="quotePage" style={{'height':'100vh', backgroundColor:'pink'}}>
            <div className="quote">{quoteDB[randomPage]["quote"]}</div>
            <div className="quoteSource">{
                quoteDB[randomPage]["source"] !== ''
                ?
                quoteDB[randomPage]["source"]
                :
                'unknown'
            }</div>
        </div>
    )
}
 
export default Quote;