import { Link, Route, Routes, Navigate } from 'react-router-dom';
import NavBar from './pages/NavBar';
import Home from './pages/home';
import RandomPage from './pages/RndmPage';
import Notfound from './pages/notFound';
import Quote from './pages/quote';
import Hdri from './pages/hdri';
import LifeBalance from './pages/lifeBalance';
import ObjInfo from './pages/objInfo';
import ObjSearch from './pages/objSearch';
import ObjNotFound from './pages/objNotFound';

function App() {
  return (
    <>
    {
    <Routes>
        <Route path='/' element={<RandomPage/>}/>

        <Route path='/quote' element={ <Quote/> }/>

        <Route path='/hdri' element={ <Hdri/> }/>

        <Route path='/balance' element={ <LifeBalance/> }/>

        <Route path='/balance'>
          <Route path=':userYear' element={<LifeBalance/>}/>
        </Route>

        <Route path='/obj' element={<ObjSearch/>} />
        <Route path='/obj'>
          <Route path=':id' element={<ObjInfo/>}/>
          <Route path='notFound' element={<ObjNotFound/>}/>
          <Route path='*' element={<ObjNotFound/>}/>
        </Route>

        <Route path='/*' element={<Notfound/>}/>

        {/* <Route path='/obj' element={<ObjSearch/>} />
        <Route path='/obj'>
          <Route path=':id' element={<ObjInfo/>}/>
          <Route path='*' element={<ObjSearch/>}/>
        </Route>
        <Route path='/about' element={<About/>}/>
        <Route path='/*' element={<NotFound/>}/> */}
      </Routes>
    }
    </>
  );
}
export default App;

