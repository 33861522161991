const pageDataBase =  [
        {
            "pageTitle":"3D",
            "url": "3d"
        },
        {
            "pageTitle":"Pick Your Weapon",
            "url": "work/drinks"
        },
        {
            "pageTitle":"Suculentas",
            "url": "work/suculentas"
        },
        {
            "pageTitle":"Clothes Pins",
            "url": "work/pins"
        },
        {
            "pageTitle":"What's in the box?",
            "url": "work/box"
        },
        {
            "pageTitle":"F*@! Hot Day!",
            "url": "work/fhd"
        },
        {
            "pageTitle":"Motion Machine",
            "url": "work/motion-machine"
        },
        {
            "pageTitle":"Reel",
            "url": "work/reel"
        },
        {
            "pageTitle":"NotWork",
            "url": "notwork"
        },
        {
            "pageTitle":"Places",
            "url": "places"
        }
    ]

export default pageDataBase