
// ACTUALIZER EN DROPBOX/PROJECTOS
const quoteDB =  [
    {"quote":"Done is better than perfect.", "source":"Hayley Akins at her podcast, but surely someone else said it first."},
    {"quote":"Life is an art, not a science.", "source":"Steal Like an Artist"},
    {"quote":"Alrededor de la fogata","source":"Spark & Fire Podcast"},
    {"quote":"Sharp and Sweet","source":""},
    {"quote":"...art's a language, and even though you've learned the language, it serves no purpose unless you have something to say.","source":"Episodio con Cabeza Patata"},
    {"quote":"If you want to make God laugh, tell him your plans.","source":"Barney Stinson"},
    {"quote":"Can you remember who you were before the world told you who you should be?","source":"C. Bukowski"},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""},
    // {"quote":"","source":""}
]

export default quoteDB