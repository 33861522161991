import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import hdriDB from "../data/hdris.json"
import '../css/hdri.css';
import '../css/alertStyle.css';

const Hdri = () => {

    

    const copyText =  async (text) => {
        const alertBox = document.querySelector('.alertBox');
        const hideAlert = () => {
          alertBox.setAttribute("style", 'opacity:0;')
        }
        hideAlert();  
        await navigator.clipboard.writeText(text)
        //const markup = `<div class="alertBox">${text}</div>`;
        
        alertBox.innerHTML = `<span class="alertDimmed">Copied:</span><br> ${text}`
        alertBox.setAttribute("style", 'opacity:1; transform: translate(-50%, 20%);')
        window.setTimeout(hideAlert, 3000);
      }
    
    return (
        <div className="pageHdri">
            <div className="alertBox"></div>
            <div className="hdriSection">
                <div className="pageHeader">
                    <div className="pageTitle">HDRI</div>
                </div>
            </div>
            <div className="hdriSection">  
                {hdriDB.studio.map((i, index) => 
                    <div className="hdriCard" style={{backgroundImage : `url(./img/hdriGallery/${i.img}.jpg)`, minWidth:`${index%3==0?'500px':'320px'}`}}  onClick={()=>{copyText(`${i.img}.${i.type}`)}} key={i.img}>
                        <div className="hdriCardInfo" style={i.fav==true? {backgroundColor : '#0000ff88' }: null }>{i.img}</div>
                    </div>
                )}
                    {hdriDB.environment.map((i,index) => 
                        <div className="hdriCard" style={{backgroundImage : `url(./img/hdriGallery/${i.img}.jpg)`, minWidth:`${index%3==0?'500px':'320px'}`}}  onClick={()=>{copyText(`${i.img}.${i.type}`)}} key={i.img}>
                        <div className="hdriCardInfo" style={i.fav==true? {backgroundColor : '#ff9d20' }: null }>{i.img}</div>
                    </div>
                )}
            </div>
            <div className="hdriSection" style={ {justifyContent:'center' } }>
                <div className="footer" style={ {marginTop : '100px', marginBottom:'100px' } }><div className="wipInfo" style={{'color':'#666666'}}>↗︎ bot.vicv.co</div></div>
            </div>
            
        </div>
  );
}
 
export default Hdri;