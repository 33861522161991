import { useNavigate } from "react-router-dom";
import SearchBar from '../pages/SearchBar';
import '../css/objCataloge.css';


const ObjSearch = () => {
    
    const navigate = useNavigate()
    const handleClick = () => {
        const lookFor = document.getElementById("lookFor").value
        navigate('/obj/' + lookFor)
    }

    const handleEnter = (e) => {
        if(e.keyCode == 13){

            handleClick()
        }
    }

    return (
        <>
        <div className="objDbPage">
            <h2>Item not found</h2>
            <div>
            <input type="number" className="input" name="" id="lookFor" onKeyDown={handleEnter} />
            <button className='buttonCataloge' onClick={handleClick}>Search</button>
            </div>
            
        </div>
       </>
    );
}
export default ObjSearch;