import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import objLocalDB from "../data/db_objects.json"
import '../css/objCataloge.css'
import { Helmet } from 'react-helmet';

const ObjInfo = () => {
    const [data, setData] = useState({})
    const [print, setPrint] = useState(false)

    const url = "https://raw.githubusercontent.com/victorverdugo/objCataloge/main/db_objects.json"
    const {id} = useParams()
    let urlId = '0000'
    
    useEffect(() => {
        fetch(url)
        .then(res => {
            return res.json();
        })
        .then(data => {
            setData(data)
            
        })
        .then(data => {
            setPrint(true)
            document.title = 'Obj-Cataloge'
        })
    
    }, [])
      
    if(id.length<2){
        urlId = '000'+id
    }else if(id.length<3){
        urlId = '00'+id
    }else if(id.length<4){
        urlId = '0'+id
    }
  
    const navigate = useNavigate()
    const handleClick = () => {
        
        const lookFor = document.getElementById("lookFor").value
        navigate(`/obj/${lookFor}`)
    }

    const handleEnter = (e) => {
        if(e.keyCode === 13){
            handleClick()
        }
    }

    return (
        <>
             <Helmet>
                <title>{ id }</title>
            </Helmet>
            {print===true
            ?
            data[id]===undefined
            ?
                <div className="objDbPage">
                    <div className="objDbCont">
                        <h2>Item {id} not found</h2>
                        <div className="searchSection">
                            <input type="number" className='input' name="" id="lookFor" onKeyDown={handleEnter} />
                            <button className='buttonCataloge' onClick={handleClick}>Search</button>
                        </div>
                    </div> 
                </div>
            :
                <div className="objDbPage">
                    <div className="objDbCont">
                        <img src={`../img/objCataloge/objCataloge_${urlId}.jpg`} alt="" className="heroImg"/>
                        <div className="infoCont">
                            <div className="objBy">OBJECT {id}</div>
                            <div className="objName">{data[id]['name']}</div>
                            <div className="objSubtitle" style={{display:data[id]['subtitle'] ? 'flex' : 'none'}}>{data[id]['subtitle'] ? data[id]['subtitle'] : null}</div>
                            <div className="objBy" style={{display:data[id]['by'] ? 'flex' : 'none'}}>{data[id]['by'] ? data[id]['by'] : null}</div>
                            <div className="objDesc" style={{display:data[id]['desc'] ? 'flex' : 'none'}}>{data[id]['desc']}</div>  
                            
                            <div className="objMeta">
                                <div className="objBy" style={{display:data[id]['place'] ? 'flex' : 'none'}}>{data[id]['place'] ? data[id]['place'] : null}</div>
                                <div className="objBy" style={{display: data[id]['place']!==undefined &&data[id]['place'].length>0 && data[id]['date']!==undefined && data[id]['date'].length>0 ? 'flex' : 'none'}}>-</div>
                                <div className="objBy" style={{display:data[id]['date'] ? 'flex' : 'none'}}>{data[id]['date'] ? data[id]['date'] : null}</div>
                                
                            </div>
                        </div>
                        <div className="searchSection">
                            <input type="number" className='input' name="" id="lookFor" onKeyDown={handleEnter} />
                            <button className='buttonCataloge' onClick={handleClick}>Search</button>
                        </div>
                    </div> 
                </div>
            :
                <div className="objDbPage">
                    <div className="objDbCont">
                        Loading
                    </div> 
                </div>
            }
        </>
    );
}
 
export default ObjInfo;